import React, { FC } from 'react';
import MaterialTable, { Column } from 'material-table';
import { useHistory } from 'react-router-dom';
import FilterHdr from '@material-ui/icons/FilterHdr';

import Page from '../../components/page';
import { useMountains } from '../../hooks/useMountains';

import { Mountain } from '../../types/mountains';
import { Paper } from '@material-ui/core';
import AwesomePaper from '../../components/awesome-paper';

const columns: Column<Mountain>[] = [
  { title: 'Name', field: 'key' },
  { title: 'Gold', field: 'gold', type: 'numeric' },
  { title: 'Level', field: 'level', type: 'numeric' },
];

const Mountains: FC = () => {
  const {
    mountains,
    addMountain,
    removeMountain,
    updateMountain,
  } = useMountains();

  const history = useHistory();

  return (
    <Page>
      <AwesomePaper>
        <MaterialTable
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          title="List of mountains"
          options={{ paging: false }}
          columns={columns}
          data={mountains}
          actions={[
            {
              icon: () => <FilterHdr />,
              tooltip: 'Go to routes',
              onClick: (event, rowData) => {
                if (Array.isArray(rowData)) {
                  return;
                }

                history.push(`/routes/${rowData.key}`);
              },
            },
          ]}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                addMountain(newData.key, newData)
                  .then(() => resolve())
                  .catch((e) => {
                    alert(`Add new mountain failed: ${e.message}`);
                    reject();
                  });
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (!oldData) {
                  alert('Old mountain does not exist.');
                  return reject();
                }

                updateMountain(oldData.key, newData.key, newData)
                  .then(() => resolve())
                  .catch((e) => {
                    alert(`Mountain update failed: ${e.message}`);
                    reject();
                  });
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                removeMountain(oldData.key)
                  .then(resolve)
                  .catch((e) => {
                    alert(`Mountain remove failed: ${e.message}`);
                    reject();
                  });
              }),
          }}
        />
      </AwesomePaper>
    </Page>
  );
};

export default Mountains;
