export function primitiveArrayToObject<T extends string | number>(array: T[]) {
  const initialValue: Record<string | number, T> = {};

  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item]: item,
    };
  }, initialValue);
}
