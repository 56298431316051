import { useContext } from 'react';
import {
  AuthUser,
  Guest,
  isAuth,
  isGuest,
  UserContext,
} from '../firebase/auth';

export const useUser = () => {
  return useContext(UserContext);
};

export const useAuthUser = (): AuthUser => {
  const userData = useUser();

  if (isAuth(userData)) {
    return userData;
  }

  throw new Error('only for authenticated user');
};

export const useGuest = (): Guest => {
  const userData = useUser();

  if (isGuest(userData)) {
    return userData;
  }

  throw new Error('only for guests');
};
