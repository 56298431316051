import { useEffect, useState } from 'react';
import { PlayerSettings } from '../types/playerSettings';

export const useProcessPlayerSettingsCSV = (files: File[] | null) => {
  const [processDataError, setProcessDataError] = useState<string>('');
  const [data, setData] = useState<PlayerSettings[]>([]);

  useEffect(() => {
    if (!files || files.length === 0) {
      console.log('No files!');
      return;
    }
    if (files.length > 1) {
      throw new Error('Multiple files even it is forbidden by html ?!');
    }

    const csvFile = files[0];

    // Safari doesn't support .text() on Blob
    csvFile.text().then((csv) => {
      // Slice(1) is for header row with column names
      const csvTable = csv.split('\n').slice(1);

      if (csvTable.length === 0) {
        setProcessDataError('Table is empty!');
        return;
      }

      const commaDelimiter = csvTable[0].indexOf(',');
      const delimiter = commaDelimiter >= 0 ? ',' : ';';

      const table = csvTable.map<string[]>((row) => row.split(delimiter));

      const parsedData = table.map<PlayerSettings>((row) => {
        if (row[0].length === 0) {
          setProcessDataError('Key is empty, wrong data');
        }

        const xpNeeded = Number(row[1]);
        const speed = Number(row[2]);
        const skill = Number(row[4]);
        const energy = Number(row[3]);

        if (
          xpNeeded === null ||
          speed === null ||
          skill === null ||
          energy === null
        ) {
          setProcessDataError('All values are not numbers, wrong data');
        }

        return {
          key: row[0],
          xp_needed: xpNeeded,
          speed: speed,
          skill: skill,
          energy: energy,
        };
      });

      setData(parsedData);
    });
  }, [files, files?.length]); // TODO: Only one import of CSV works. Fix this statement to work properly.

  return { data, processDataError };
};
