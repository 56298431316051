import React, { FC } from 'react';
import Page from '../../components/page';
import AwesomePaper from '../../components/awesome-paper';
import { useAuthUser } from '../../hooks/useUser';

const Dashboard: FC = () => {
  const { user } = useAuthUser();

  return (
    <Page>
      <AwesomePaper>Welcome in Artemis, {user.displayName} 🍻!</AwesomePaper>
    </Page>
  );
};

export default Dashboard;
