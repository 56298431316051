import * as T from '../types/route-event';

export function isLogEvent(event: T.RouteEvent): event is T.LogEvent {
  return event.name === 'LOG';
}

export function isChangeStatEvent(
  event: T.RouteEvent
): event is T.ChangeStatEvent {
  return event.name === 'STAT';
}

export function isSpawnItemEvent(
  event: T.RouteEvent
): event is T.SpawnItemEvent {
  return event.name === 'ITEM';
}

export function isMultipleEvent(event: T.RouteEvent): event is T.MultipleEvent {
  return event.name === 'MULTIPLE';
}

export function isGroupEvent(event: T.RouteEvent): event is T.GroupEvent {
  return event.name === 'GROUP';
}

export function isProbabilityEvent(
  event: T.RouteEvent
): event is T.ProbabilityEvent {
  return event.name !== 'LOG';
}
