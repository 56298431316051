import React, { FC } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EventIcon from '@material-ui/icons/Event';
import MenuBook from '@material-ui/icons/MenuBook';

import { RouteEvent } from '../../../types/route-event';
import {
  isChangeStatEvent,
  isLogEvent,
  isSpawnItemEvent,
} from '../../../utils/predicates-route-event';

export const RouteEventView: FC<{ data: RouteEvent }> = ({ data }) => {
  if (isSpawnItemEvent(data)) {
    return (
      <span>
        <AttachFileIcon /> {data.name}
      </span>
    );
  }

  if (isChangeStatEvent(data)) {
    return (
      <span>
        <EventIcon /> {data.name}
      </span>
    );
  }

  if (isLogEvent(data)) {
    return (
      <span>
        <MenuBook /> {data.name}
      </span>
    );
  }

  return <span>{data.name}</span>;
};
