import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const AddRouteDialog: FC<{
  open: boolean;
  handleClose?: () => void;
  onCreate?: (routeName: string) => void;
  disbled?: boolean;
}> = ({ open, handleClose = () => undefined, onCreate = () => undefined }) => {
  const [routeName, setRouteName] = useState('');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Create new route</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          id="name"
          label="Route name"
          value={routeName}
          onChange={(event) => setRouteName(event.target.value)}
          type="name"
          color="primary"
          fullWidth
          placeholder="Lower falls"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onCreate(routeName);
            handleClose();
            setRouteName('');
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
