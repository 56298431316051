import React, { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useUser } from '../hooks/useUser';

type Props = {
  publicRoutes: ReactNode;
  privateRoutes: ReactNode;
};

const AuthRouter = ({ publicRoutes, privateRoutes }: Props) => {
  const { isAuth } = useUser();

  return <Router>{isAuth ? privateRoutes : publicRoutes}</Router>;
};

export default AuthRouter;
