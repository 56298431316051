import React, { FC } from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import { GenericRouteEventForm } from './GenericRouteEventForm';
import { useRouteEvents } from '../../../hooks/useRouteEvents';
import { LogEvent } from '../../../types/route-event';

const useStyles = makeStyles((theme) => ({
  routeTitle: {
    color: 'primary',
    textDecoration: 'italic',
  },
}));

const LogFormSchema = Yup.object().shape({
  message: Yup.string().min(1, 'Too Short').required('Required'),
});

export const LogForm: FC<{
  routeName: string;
  mountain: string;
}> = ({ routeName, mountain }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { addEvent } = useRouteEvents(mountain, routeName);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Create Log Event
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          Create Log Event for{' '}
          <span className={classes.routeTitle}>{routeName}</span>
        </DialogTitle>
        <DialogContent dividers>
          <GenericRouteEventForm<LogEvent>
            initialValues={{ name: 'LOG', start_point: 0, message: '' }}
            onSubmit={(values, { setSubmitting }) => {
              addEvent(values).then(() => {
                setSubmitting(false);
                handleClose();
              });
            }}
            validationSchema={LogFormSchema}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
