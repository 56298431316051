import React, { FC } from 'react';
import { Route } from '../../../types/routes';
import { RouteEvent } from '../../../types/route-event';
import Warning from '../../warning';
import { isProbabilityEvent } from '../../../utils/predicates-route-event';

export const StartPoint: FC<{ route: Route; data: RouteEvent }> = ({
  route,
  data,
}) => {
  let value: string = String(data.start_point);
  let isOutOfRange = data.start_point > route.length;

  if (isProbabilityEvent(data)) {
    if (data.start_point !== data.end_point) {
      value = `${data.start_point} - ${data.end_point}`;
    }

    if (data.end_point > route.length) {
      isOutOfRange = true;
    }
  }

  return isOutOfRange ? (
    <Warning tooltip="Point of event is out of route">{value}</Warning>
  ) : (
    <span>{value}</span>
  );
};
