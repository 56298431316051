import { useState, useEffect, useCallback } from 'react';
import { LEVEL, PLAYER_SETTINGS } from '../firebase/refs';
import {
  PlayerSettings,
  PlayerSettingsResponse,
  PlayerSettingsFirebase,
} from '../types/playerSettings';
import { convertObjectToArray } from '../utils/convertObjectToArray';
import { useDatabase } from './useDatabase';
import { isInt } from '../types/items';

const initPlayerSettingsData = (
  data?: Partial<PlayerSettingsFirebase>
): PlayerSettingsFirebase => {
  data = data ?? {};

  return {
    xp_needed: data.xp_needed ? Number(data.xp_needed) : 0,
    energy: data.energy ? Number(data.energy) : 0,
    skill: data.skill ? Number(data.skill) : 0,
    speed: data.speed ? Number(data.speed) : 0,
  };
};

export const usePlayerSettings = () => {
  const database = useDatabase();
  const [playerSettings, setPlayerSettings] = useState<PlayerSettings[]>([]);
  const [loadingImport, setLoadingImport] = useState(false);

  const updatePlayerSettings = useCallback(
    async (
      oldLevel: string,
      newLevel: string,
      data: Partial<PlayerSettingsFirebase>
    ) => {
      if (!oldLevel || !newLevel) {
        throw new Error('Level is required.');
      }

      const playerSettingsRef = database.ref(PLAYER_SETTINGS);
      const playerSettingsData = initPlayerSettingsData(data);

      const dataToUpdate = {
        [newLevel]: playerSettingsData,
        ...(oldLevel !== newLevel && { [oldLevel]: null }),
      };

      return playerSettingsRef.update(dataToUpdate);
    },
    [database]
  );

  const addPlayerSettings = useCallback(
    async (
      level: string,
      xp_needed: number,
      skill: number,
      speed: number,
      energy: number
    ) => {
      if (!level) {
        throw new Error('Level is required');
      }

      if (typeof Number(level) !== 'number') {
        throw new Error('Level needs to be integer.');
      }

      if (
        !isInt(xp_needed) ||
        !isInt(skill) ||
        !isInt(speed) ||
        !isInt(energy)
      ) {
        throw new Error('All values are required to be numbers!');
      }

      xp_needed = Number(xp_needed);
      skill = Number(skill);
      speed = Number(speed);
      energy = Number(energy);

      const firePlayerSettings = database
        .ref(PLAYER_SETTINGS)
        .child(level.toString());

      await firePlayerSettings.set({
        xp_needed,
        skill,
        speed,
        energy,
      });
      return firePlayerSettings;
    },
    [database]
  );

  const addAllPlayerSettings = useCallback(
    async (playerSettings: PlayerSettings[]) => {
      setLoadingImport(true);

      await database.ref(PLAYER_SETTINGS).remove();

      playerSettings.forEach((settings) => {
        addPlayerSettings(
          settings.key,
          settings.xp_needed,
          settings.skill,
          settings.speed,
          settings.energy
        );
      });

      setLoadingImport(false);
    },
    [database, addPlayerSettings]
  );

  const removePlayerSettings = useCallback(
    async (level: string) => {
      return database.ref(LEVEL(level)).remove();
    },
    [database]
  );

  useEffect(() => {
    const listener = database.ref(PLAYER_SETTINGS).on('value', (snapshot) => {
      if (snapshot) {
        const playerSettingsResponse = snapshot.val() as PlayerSettingsResponse;
        if (playerSettingsResponse) {
          setPlayerSettings(convertObjectToArray(playerSettingsResponse));
        }
      }
    });

    return () => {
      listener(null);
    };
  }, [database]);

  return {
    playerSettings,
    addPlayerSettings,
    addAllPlayerSettings,
    removePlayerSettings,
    updatePlayerSettings,
    loadingImport,
  };
};
