import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MountainIcon from '@material-ui/icons/FilterHdr';
import GearIcon from '@material-ui/icons/LocalMall';
import PlayerIcon from '@material-ui/icons/Accessibility';
import EventIcon from '@material-ui/icons/Event';
import SpeedIcon from '@material-ui/icons/Speed';
import { LinkItem } from './link-item';

export const mainListItems = (
  <div>
    <LinkItem to="/" title="Dashboard" icon={<DashboardIcon />} />
    <LinkItem to="/player" title="Player" icon={<PlayerIcon />} />
    <LinkItem to="/mountains" title="Mountains" icon={<MountainIcon />} />
    <LinkItem to="/items" title="Gear" icon={<GearIcon />} />
    <LinkItem to="/stat-events" title="Stat events" icon={<EventIcon />} />
    <LinkItem to="/speed" title="Speed" icon={<SpeedIcon />} />
  </div>
);
