import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragDropContextProps,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd';
import ListIcon from '@material-ui/icons/List';

type Item<T> = T & { order: number; key: string };

export interface Props<T> {
  items: Item<T>[];
  onReorderEnd: (items: Item<T>[]) => Promise<unknown>;
}

// a little function to help us with reordering the result
const reorder = <T,>(list: Item<T>[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({ ...item, order: index }));
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggableProvidedDraggableProps['style']
) => ({
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging
    ? 'linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%)'
    : '#ffffff',
  borderRadius: '0 0.5rem 0.5rem 0.5rem',
  boxShadow:
    '0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05)',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#efefef' : 'none',
  padding: grid,
  width: 500,
  borderRadius: '0.5rem',
});

export const ReorderList = <T,>(props: Props<T>) => {
  const [disableDragging, setDisableDragging] = useState(false);

  const onDragEnd: DragDropContextProps['onDragEnd'] = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      props.items,
      result.source.index,
      result.destination.index
    );

    try {
      setDisableDragging(true);
      await props.onReorderEnd(reorderedItems);
    } catch (e) {
      alert(`Ordering failed ${e.message}`);
    }
    setDisableDragging(false);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {props.items.map((item, index) => (
              <Draggable
                key={item.key}
                draggableId={item.key}
                index={index}
                isDragDisabled={disableDragging}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    // @ts-ignores
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ListIcon />
                    {item.key}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
