import React from 'react';
import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl/FormControl';
import TextField from '@material-ui/core/TextField/TextField';
import { Formik, FormikProps } from 'formik';

import { RouteEvent } from '../../../types/route-event';
import Button from '@material-ui/core/Button';
import { FormikHelpers } from 'formik/dist/types';

const GenericFormSchema = Yup.object().shape({
  start_point: Yup.number().min(0, 'Minimal number is 0').required('Required'),
  name: Yup.string()
    .min(1, 'Too Short')
    .max(100, 'Too Long')
    .required('Required'),
  message: Yup.string().min(1, 'Too Short'),
});

export function GenericRouteEventForm<T extends RouteEvent = RouteEvent>({
  children,
  initialValues,
  validationSchema,
  onSubmit,
}: {
  initialValues: T;
  children?: (arg: FormikProps<T>) => React.ReactNode;
  validationSchema?: any | (() => any);
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
}) {
  const schema = validationSchema
    ? GenericFormSchema.concat(validationSchema)
    : GenericFormSchema;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {(props) => {
        const {
          isSubmitting,
          errors,
          submitForm,
          values,
          handleChange,
          handleBlur,
        } = props;

        return (
          <FormControl fullWidth>
            <TextField
              autoFocus
              margin="dense"
              label="Event Name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={!!errors.name}
              helperText={errors.name}
              disabled
              fullWidth
              required
            />
            <TextField
              autoFocus
              margin="dense"
              label="Start point"
              type="number"
              name="start_point"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.start_point}
              error={!!errors.start_point}
              helperText={errors.start_point}
              fullWidth
              disabled={isSubmitting}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              error={!!errors.message}
              helperText={errors.message}
              fullWidth
              disabled={isSubmitting}
            />
            {children && children(props)}
            <Button
              autoFocus
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Create
            </Button>
          </FormControl>
        );
      }}
    </Formik>
  );
}
