import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import Player from '../pages/player';
import Mountains from '../pages/mountains';
import Items from '../pages/gear';
import StatEvents from '../pages/stat-events';
import Routes from '../pages/routes';
import Speed from '../pages/speed';
import AuthRouter from './router';
import SignIn from '../pages/SignIn';

const AppRouter: FC = () => (
  <AuthRouter
    publicRoutes={<SignIn />}
    privateRoutes={
      <Switch>
        <Route path="/player">
          <Player />
        </Route>
        <Route path="/mountains">
          <Mountains />
        </Route>
        <Route path="/items">
          <Items />
        </Route>
        <Route path="/stat-events">
          <StatEvents />
        </Route>
        <Route path="/speed">
          <Speed />
        </Route>
        <Route
          path={[
            '/routes/:mountain/events/:route',
            '/routes/:mountain',
            '/routes/:mountain/reorder',
          ]}
        >
          <Routes />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    }
  />
);

export default AppRouter;
