import { useState, useEffect, useCallback } from 'react';
import { useDatabase } from './useDatabase';
import { RoutesResponse, Route, Summit } from '../types/routes';
import { ROUTES } from '../firebase/refs';

export const initSummit = (data?: Partial<Summit>): Summit => {
  data = data ?? {};

  return {
    gold: {
      reward: {
        gem: data.gold?.reward?.gem ?? 0,
        gold: data.gold?.reward?.gold ?? 0,
      },
      condition: {
        seconds: data.gold?.condition?.seconds ?? 0,
      },
    },
    bronze: {
      reward: {
        gem: data.bronze?.reward?.gem ?? 0,
        gold: data.bronze?.reward?.gold ?? 0,
      },
      condition: {
        seconds: data.bronze?.condition?.seconds ?? 0,
      },
    },
    silver: {
      reward: {
        gem: data.silver?.reward?.gem ?? 0,
        gold: data.silver?.reward?.gold ?? 0,
      },
      condition: {
        seconds: data.silver?.condition?.seconds ?? 0,
      },
    },
  };
};

const initRoute = (order: number, data?: Partial<Route>): Route => {
  data = data ?? {};

  return {
    length: data.length ? Number(data.length) : 0,
    repeatGold: data.repeatGold ? Number(data.repeatGold) : 0,
    events: data.events = {},
    skill: data.skill ? Number(data.skill) : 0,
    summit: initSummit(data.summit),
    xp: data.xp ? Number(data.xp) : 0,
    ticketPrice: data.ticketPrice ? Number(data.ticketPrice) : 0,
    order,
  };
};

export const useRoutes = (mountain: string) => {
  const database = useDatabase();
  const [routes, setRoutes] = useState<RoutesResponse>({});
  const [loading, setLoading] = useState(false);

  const addRoute = useCallback(
    async (routeName: string, data?: Partial<Route>) => {
      if (!routeName) {
        throw new Error('Route name is required');
      }

      if (loading) {
        throw new Error('The route could not be added during loading');
      }

      return database
        .ref(ROUTES(mountain))
        .child(routeName)
        .set(initRoute(Object.keys(routes).length, data));
    },
    [database, mountain, routes, loading]
  );

  const removeRoute = useCallback(
    async (routeName: string) => {
      console.log(routeName);
      if (!routeName) {
        throw new Error('Route name is required');
      }

      if (loading) {
        throw new Error('The route could not be removed during loading');
      }

      const roteWillBeRemoved = routes[routeName];

      if (!roteWillBeRemoved) {
        throw new Error('Route with this name does not exists');
      }

      const routesRef = database.ref(ROUTES(mountain));

      const routesSnapshot = await routesRef.once('value');

      const dataToUpdate: Record<string, Partial<Route> | null> = {
        [routeName]: null,
      };

      routesSnapshot.forEach((child) => {
        if (!child.key || child.key === routeName) {
          return;
        }

        const route = child.val();
        const order = route?.order;

        if (typeof order === 'number' && order >= roteWillBeRemoved.order) {
          dataToUpdate[child.key] = {
            ...route,
            order: order - 1,
          };
        }
      });

      return routesRef.update(dataToUpdate);
    },
    [database, mountain, loading, routes]
  );

  const updateRoute = useCallback(
    async (
      oldRouteName: string,
      newRouteName: string,
      data: Partial<Route>
    ) => {
      if (!oldRouteName || !newRouteName) {
        throw new Error('Name of route is required.');
      }

      const routesRef = database.ref(ROUTES(mountain));

      const currentRouteSnap = await routesRef
        .child(oldRouteName)
        .once('value');

      const currentRouteVal = currentRouteSnap.val();

      if (!currentRouteVal) {
        throw new Error('Route does not exists');
      }

      if (typeof data.order === 'undefined') {
        throw new Error('Route order must be specified!');
      }

      // TODO: Update routes order when value changed
      if (data.order !== currentRouteVal.order) {
        throw new Error(
          `Route order is not equal as the database value [${data.order}, ${currentRouteVal.order}]. Route could not be updated.`
        );
      }

      const routeData = initRoute(data.order, data);
      routeData.events = currentRouteVal.events ?? {};

      const dataToUpdate = {
        [newRouteName]: routeData,
        ...(oldRouteName !== newRouteName && { [oldRouteName]: null }),
      };

      return routesRef.update(dataToUpdate);
    },
    [database, mountain]
  );

  const updateRoutesOrder = useCallback(
    (mountain: string, routes: Array<{ key: string; order: number }>) => {
      const routesRef = database.ref(ROUTES(mountain));

      const updateData = Object.fromEntries(
        routes.map(({ key, order }) => [`${key}/order`, order])
      );

      return Promise.all([routesRef.update(updateData)]);
    },
    [database]
  );

  useEffect(() => {
    if (!mountain) {
      return;
    }

    setLoading(true);
    const listener = database.ref(ROUTES(mountain)).on('value', (snapshot) => {
      if (snapshot) {
        setRoutes(snapshot.val() ?? {});
      }
      setLoading(false);
    });

    return () => {
      listener(null);
    };
  }, [database, mountain]);

  return { routes, updateRoute, addRoute, removeRoute, updateRoutesOrder };
};
