import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';

const Warning: React.FC<{ tooltip: string }> = ({ children, tooltip }) => (
  <Tooltip title={tooltip} arrow>
    <span style={{ color: '#ff8433' }}>
      <WarningIcon fontSize="small" />
      {children}️
    </span>
  </Tooltip>
);

export default Warning;
