import React, { FC, useMemo } from 'react';
import MaterialTable, { Column } from 'material-table';
import ZoomIn from '@material-ui/icons/ZoomIn';
import LinkIcon from '@material-ui/icons/Link';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Route } from '../../../types/routes';
import { RouteEvent } from '../../../types/route-event';
import { convertObjectToArray } from '../../../utils/convertObjectToArray';
import { StartPoint } from './StartPoint';
import { AddRouteEvent } from './AddRouteEvent';
import { useRouteEvents } from '../../../hooks/useRouteEvents';
import { AdditionalInfo } from './AdditionalInfo';
import { useStatEvents } from '../../../hooks/useStatEvents';
import { useItems } from '../../../hooks/useItems';
import { RouteEventView } from './RouteEventView';
import { FIREBASE_CONSOLE_URL, ROUTE_EVENT } from '../../../firebase/refs';
import { Paper } from '@material-ui/core';

export const Events: FC<{ route: Route; name: string; mountain: string }> = ({
  route,
  name,
  mountain,
}) => {
  const { removeEvent } = useRouteEvents(mountain, name);
  const { statEvents } = useStatEvents();
  const { items } = useItems();

  const [dialog, setDialog] = React.useState({
    open: false,
    title: '',
    description: '',
  });

  const handleOpen = (title: string, description: string) => {
    setDialog({ open: true, title: title, description: description });
  };

  const handleClose = () => {
    setDialog({ ...dialog, open: false });
  };

  const columns: Column<RouteEvent & { key: string }>[] = [
    {
      title: 'Event type',
      field: 'name',
      render: (data) => <RouteEventView data={data} />,
    },
    {
      title: 'Start Point',
      field: 'start_point',
      render: (data) => <StartPoint route={route} data={data} />,
    },
    {
      title: 'Message',
      field: 'message',
    },
    {
      title: 'Additional Info',
      field: '',
      render: (data) => (
        <AdditionalInfo
          route={route}
          data={data}
          statEvents={statEvents}
          items={items}
        />
      ),
    },
  ];

  const events = useMemo(() => {
    const events = convertObjectToArray(route.events);
    events.sort((a, b) => a.start_point - b.start_point);
    return events;
  }, [route.events]);

  return (
    <>
      <MaterialTable
        title={`Route events for ${name}`}
        columns={columns}
        data={events}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[
          {
            icon: () => <ZoomIn />,
            tooltip: 'JSON detail',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData)) {
                return;
              }

              handleOpen(rowData.name, JSON.stringify(rowData, null, '\t'));
            },
          },
          {
            icon: () => <LinkIcon />,
            tooltip: 'Firebase console',
            onClick: (event, rowData) => {
              if (Array.isArray(rowData)) {
                return;
              }

              window.open(
                FIREBASE_CONSOLE_URL(ROUTE_EVENT(mountain, name, rowData.key)),
                '_blank'
              );
            },
          },
        ]}
        editable={{ onRowDelete: (oldData) => removeEvent(oldData.key) }}
      />
      <AddRouteEvent routeName={name} mountain={mountain} />
      <Dialog open={dialog.open} onClose={handleClose}>
        <DialogTitle>
          Info <em>{dialog.title}</em>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <pre>{dialog.description}</pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
