import { useEffect, useState } from 'react';
import { SpeedConverter } from '../types/speedConverter';

export const useProcessSpeedConverterCSV = (files: File[] | null) => {
  const [processDataError, setProcessDataError] = useState<string>('');
  const [data, setData] = useState<SpeedConverter[]>([]);

  useEffect(() => {
    if (!files || files.length === 0) {
      console.log('No Files!');
      return;
    }

    if (files.length > 1) {
      throw new Error('Multiple files even it is forbidden by html ?!');
    }

    const csvFile = files[0];

    // Safari doesn't support .text() on Blob
    csvFile.text().then((csv) => {
      // Slice(1) is for header row with column names
      const csvTable = csv.split('\n').slice(1);

      if (csvTable.length === 0) {
        setProcessDataError('Table is empty!');
        return;
      }

      const commaDelimiter = csvTable[0].indexOf(',');
      const delimiter = commaDelimiter >= 0 ? ',' : ';';

      const table = csvTable.map<string[]>((row) => row.split(delimiter));

      const parsedData = table.map<SpeedConverter>((row) => {
        const logicSpeed = row[0];

        if (logicSpeed === null || logicSpeed.length === 0) {
          setProcessDataError('Key is empty, wrong data');
        }

        const realSpeed = Number(row[1]);

        if (realSpeed === null) {
          setProcessDataError('All values are not numbers, wrong data');
        }

        if (
          typeof Number(logicSpeed) !== 'number' ||
          typeof Number(realSpeed) !== 'number'
        ) {
          setProcessDataError('All values are not numbers, wrong data');
        }

        return {
          key: logicSpeed,
          value: realSpeed,
        };
      });

      setData(parsedData);
    });
  }, [files, files?.length]);

  return { data, processDataError };
};
