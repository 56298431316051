import { initSummit } from '../../hooks/useRoutes';
import React, { FC } from 'react';
import { RouteColumn } from './RoutesTable';
import { Summit as SummitType } from '../../types/routes';
import objectPath, { Path } from 'object-path';

export const SummitRenderComponent: RouteColumn['render'] = (data) => {
  const summit = initSummit(data.summit);

  return (
    <table style={{ textAlign: 'right' }}>
      <tr>
        <td>🥇</td>
        <td>{summit.gold.reward.gold} 💰</td>
        <td>{summit.gold.reward.gem} 💎</td>
        <td>{summit.gold.condition.seconds}s</td>
      </tr>
      <tr>
        <td>🥈</td>
        <td>{summit.silver.reward.gold} 💰</td>
        <td>{summit.silver.reward.gem} 💎</td>
        <td>{summit.silver.condition.seconds}s</td>
      </tr>
      <tr>
        <td>🥉</td>
        <td>{summit.bronze.reward.gold} 💰</td>
        <td>{summit.bronze.reward.gem} 💎</td>
        <td>{summit.bronze.condition.seconds}s</td>
      </tr>
    </table>
  );
};

const mutateSummitOnChange = (
  summit: SummitType,
  newValue: {
    value: string;
    path: Path;
  }
) => {
  objectPath.set(summit, newValue.path, newValue.value);
  return summit;
};

const SummitInput: FC<{
  summit: SummitType;
  onChange: (value: any) => void;
  path: Path;
  textAfter?: string;
}> = ({ summit, onChange, path, textAfter }) => (
  <div style={{ display: 'flex' }}>
    <input
      type="text"
      value={objectPath.get(summit, path)}
      style={{
        width: '40px',
      }}
      onChange={(e) =>
        onChange(
          mutateSummitOnChange(summit, {
            value: e.target.value,
            path,
          })
        )
      }
    />
    <span style={{ marginRight: '5px', marginLeft: '5px' }}>{textAfter}</span>
  </div>
);

const SummitInputGroup: FC<{
  summit: SummitType;
  summitType: keyof SummitType;
  onChange: (value: any) => void;
}> = (data) => (
  <>
    <SummitInput
      summit={data.summit}
      onChange={data.onChange}
      path={`${data.summitType}.reward.gold`}
      textAfter="💰"
    />
    <SummitInput
      summit={data.summit}
      onChange={data.onChange}
      path={`${data.summitType}.reward.gem`}
      textAfter="💎"
    />
    <SummitInput
      summit={data.summit}
      onChange={data.onChange}
      path={`${data.summitType}.condition.seconds`}
      textAfter="m"
    />
  </>
);

export const SummitEditableComponent: RouteColumn['editComponent'] = ({
  onChange,
  value,
}) => {
  const summit = initSummit(value);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        🥇
        <SummitInputGroup
          summitType="gold"
          onChange={onChange}
          summit={summit}
        />
      </div>
      <div style={{ display: 'flex' }}>
        🥈
        <SummitInputGroup
          summitType="silver"
          onChange={onChange}
          summit={summit}
        />
      </div>
      <div style={{ display: 'flex' }}>
        🥉
        <SummitInputGroup
          summitType="bronze"
          onChange={onChange}
          summit={summit}
        />
      </div>
    </div>
  );
};
