import React, { FC } from 'react';
import AppRouter from '../../router';
import './base.scss';
import { DatabaseProvider } from '../../firebase/database';
import { UserProvider } from '../../firebase/auth';

const Base: FC = () => (
  <UserProvider>
    <DatabaseProvider>
      <AppRouter />
    </DatabaseProvider>
  </UserProvider>
);

export default Base;
