import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable, { Column } from 'material-table';
import { useRoutes } from '../../hooks/useRoutes';
import LinkIcon from '@material-ui/icons/Link';
import { FIREBASE_CONSOLE_URL, ROUTE } from '../../firebase/refs';
import { RouteWithTitle } from './index';
import { Paper } from '@material-ui/core';
import { SummitRenderComponent, SummitEditableComponent } from './summit';

type HandleRouteMethods = Pick<
  ReturnType<typeof useRoutes>,
  'updateRoute' | 'removeRoute'
>;

export type RouteColumn = Column<RouteWithTitle>;

const SimpleColumn: FC = ({ children }) => <div>{children}</div>;

const columns: RouteColumn[] = [
  { title: 'Title', field: 'key' },
  {
    title: 'Length 🥾',
    field: 'length',
    type: 'numeric',
    render: (data) => <SimpleColumn>{`${data.length}m`}</SimpleColumn>,
  },
  {
    title: 'Repeat 💰',
    field: 'repeatGold',
    type: 'numeric',
    render: (data) => <SimpleColumn>{`${data.repeatGold} 💰`}</SimpleColumn>,
  },
  { title: 'Skill', field: 'skill', type: 'numeric' },
  { title: 'XP', field: 'xp', type: 'numeric' },
  {
    title: 'Ticket price',
    field: 'ticketPrice',
    type: 'numeric',
    render: (data) => <SimpleColumn>{`${data.ticketPrice} 💰`}</SimpleColumn>,
  },
  {
    title: 'Summit 🏆',
    field: 'summit',
    render: SummitRenderComponent,
    editComponent: SummitEditableComponent,
  },
  { title: 'Order', field: 'order', type: 'numeric', editable: 'never' },
];

export const RoutesTable: FC<
  {
    data: RouteWithTitle[];
    mountain: string;
  } & HandleRouteMethods
> = ({ data, removeRoute, updateRoute, mountain }) => {
  const history = useHistory();

  return (
    <MaterialTable
      title="Routes"
      columns={columns}
      data={data}
      options={{
        sorting: false,
        search: true,
        paging: false,
        defaultExpanded: true,
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      actions={[
        {
          icon: 'events',
          tooltip: 'Show route events',
          onClick: (event, rowData) => {
            if (!Array.isArray(rowData)) {
              history.push(`/routes/${mountain}/events/${rowData.key}`);
            }
          },
        },
        {
          icon: () => <LinkIcon />,
          tooltip: 'Firebase console',
          onClick: (event, rowData) => {
            if (Array.isArray(rowData)) {
              return;
            }

            window.open(
              FIREBASE_CONSOLE_URL(ROUTE(mountain, rowData.key)),
              '_blank'
            );
          },
        },
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            if (!oldData) {
              alert('Old route does not exist.');
              return reject();
            }

            updateRoute(oldData.key, newData.key, newData)
              .then(resolve)
              .catch((e) => {
                alert(`Route update failed: ${e.message}`);
                reject();
              });
          }),
        onRowDelete: (oldData) => removeRoute(oldData.key),
      }}
    />
  );
};
