import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

const H1: FC = ({ children }) => (
  <Typography
    component="h1"
    variant="h2"
    align="center"
    color="textPrimary"
    gutterBottom
  >
    {children}
  </Typography>
);

export { H1 };
