import React, { FC } from 'react';
import * as Yup from 'yup';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useStatEvents } from '../../../hooks/useStatEvents';
import { ProbabilityEventForm } from './ProbabilityEventForm';

const ChangeStatEventFormSchema = Yup.object().shape({
  event: Yup.string().required(),
});

export const StatEventForm: FC<{
  routeName: string;
  mountain: string;
}> = ({ routeName, mountain }) => {
  const { statEvents } = useStatEvents();

  return (
    <ProbabilityEventForm
      title="Create Stat Event"
      routeName={routeName}
      mountain={mountain}
      validationSchema={ChangeStatEventFormSchema}
      initialValues={{
        name: 'STAT',
        start_point: 0,
        message: '',
        start_distance: 1,
        probability: 50,
        event: '',
        end_point: undefined,
        end_distance: undefined,
      }}
      render={({
        values,
        errors,
        handleChange,
        isSubmitting,
        handleBlur,
        setFieldValue,
      }) => (
        <Autocomplete
          options={statEvents}
          getOptionLabel={(option) => option.key}
          style={{ width: 300 }}
          onChange={(_, value) => {
            if (value) {
              setFieldValue('event', value.key);
            } else {
              setFieldValue('event', '');
            }
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Stat event"
              fullWidth
              disabled={isSubmitting}
              required
              name="event"
              error={!!errors.event}
              helperText={errors.event}
              value={values.event}
            />
          )}
        />
      )}
    />
  );
};
