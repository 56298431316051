import MaterialTable, { Column } from 'material-table';
import React, { FC, useEffect } from 'react';
import Page from '../../components/page';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { usePlayerSettings } from '../../hooks/usePlayerSettings';
import { PlayerSettings } from '../../types/playerSettings';
import { useFilePicker } from 'react-sage';
import { useProcessPlayerSettingsCSV } from '../../hooks/useProcessPlayerSettingsCSV';

const columns: Column<PlayerSettings>[] = [
  { title: 'Level', field: 'key' },
  { title: 'XP Needed', field: 'xp_needed', type: 'numeric' },
  { title: 'Skill', field: 'speed', type: 'numeric' },
  { title: 'Speed', field: 'skill', type: 'numeric' },
  { title: 'Energy', field: 'energy', type: 'numeric' },
];

const Player: FC = () => {
  const {
    playerSettings,
    addPlayerSettings,
    addAllPlayerSettings,
    removePlayerSettings,
    updatePlayerSettings,
    loadingImport,
  } = usePlayerSettings();

  const { files, errors, onClick, HiddenFileInput } = useFilePicker();

  const { data, processDataError } = useProcessPlayerSettingsCSV(files);

  useEffect(() => {
    if (data.length && processDataError.length === 0) {
      addAllPlayerSettings(data);
    }
  }, [data, addAllPlayerSettings, processDataError.length]);

  return (
    <Page>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {processDataError.length > 0 && (
          <Typography variant="h3" component="h3">
            {processDataError}
          </Typography>
        )}
        {errors.hasInvalidFileSize && (
          <Typography variant="h3" component="h3">
            Invalid file size
          </Typography>
        )}
        <HiddenFileInput accept="text/csv" multiple={false}></HiddenFileInput>
        <Button
          onClick={onClick}
          color="primary"
          style={{ marginBottom: '1rem', marginLeft: 'auto' }}
        >
          Import CSV
        </Button>
        {loadingImport && (
          <Typography variant="h3" component="h3">
            {'Loading...'}
          </Typography>
        )}
        <MaterialTable
          title="Player Settings"
          options={{ paging: false, exportButton: true }}
          columns={columns}
          data={playerSettings}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                addPlayerSettings(
                  newData.key,
                  newData.xp_needed,
                  newData.skill,
                  newData.speed,
                  newData.energy
                )
                  .then(() => resolve())
                  .catch((e) => {
                    alert(`Add player settings failed: ${e.message}`);
                  });
              }),
            onRowDelete: async (oldData) =>
              await removePlayerSettings(oldData.key),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (!oldData) {
                  alert('Old player settings does not exist.');
                  return reject();
                }

                updatePlayerSettings(oldData.key, newData.key, newData)
                  .then(() => resolve())
                  .catch((e) => {
                    alert(`Player settings update failed: ${e.message}`);
                    reject();
                  });
              }),
          }}
        />
      </div>
    </Page>
  );
};

export default Player;
