import { useEffect, useState, useCallback } from 'react';
import { GEAR, ITEM } from '../firebase/refs';
import { useDatabase } from './useDatabase';
import {
  Item,
  ItemFirebase,
  ItemsResponse,
  parseFirebaseItem,
} from '../types/items';
import { convertObjectToArray } from '../utils/convertObjectToArray';

export const useItems = () => {
  const database = useDatabase();
  const [items, setItems] = useState<Item[]>([]);

  const updateItem = useCallback(
    async (oldItemName: string, newItemName: string, data: ItemFirebase) => {
      if (!oldItemName || !newItemName) {
        throw new Error('Name of item is required.');
      }

      const itemsRef = database.ref(GEAR);

      const itemData = parseFirebaseItem(data);

      const dataToUpdate = {
        [newItemName]: itemData,
        ...(oldItemName !== newItemName && { [oldItemName]: null }),
      };

      return itemsRef.update(dataToUpdate);
    },
    [database]
  );

  const addItem = useCallback(
    async (name: string, data: ItemFirebase) => {
      const firebaseItem = parseFirebaseItem(data);

      const item = database.ref(GEAR).child(name);
      await item.set(firebaseItem);

      return item;
    },
    [database]
  );

  const removeItem = useCallback(
    async (item: string) => {
      return database.ref(ITEM(item)).remove();
    },
    [database]
  );

  useEffect(() => {
    const listener = database.ref(GEAR).on('value', (snapshot) => {
      if (snapshot) {
        const itemsResponse = snapshot.val() as ItemsResponse;
        if (itemsResponse) {
          setItems(convertObjectToArray(itemsResponse));
        }
      }
    });

    return () => {
      listener(null);
    };
  }, [database]);

  return { items, addItem, removeItem, updateItem };
};
