import React, { FC, useMemo, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Page from '../../components/page';
import AwesomePaper from '../../components/awesome-paper';
import { useRoutes } from '../../hooks/useRoutes';
import { H1 } from '../../components/title/h1';
import { Route } from '../../types/routes';
import { AddRouteDialog } from './AddRouteDialog';
import { RoutesTable } from './RoutesTable';
import { Events } from '../../components/route-events/route-events-table/Events';
import { ReorderList } from '../../components/reorder-list';
import { Switch, Route as RouterRoute, Link } from 'react-router-dom';

export type RouteWithTitle = Route & { key: string };

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Routes: FC = () => {
  let { mountain, route } = useParams<{ mountain?: string; route?: string }>();
  const {
    routes,
    updateRoute,
    addRoute,
    removeRoute,
    updateRoutesOrder,
  } = useRoutes(mountain ?? '');
  const classes = useStyles();
  const [routeDialogOpen, setRouteDialogOpen] = useState(false);

  const routeWithKeys: RouteWithTitle[] = useMemo(() => {
    const routeKeys = Object.keys(routes);
    routeKeys.sort((a, b) => routes[a].order - routes[b].order);

    return routeKeys.map((routeKey) => ({
      ...routes[routeKey],
      key: routeKey,
    }));
  }, [routes]);

  const selectedRoute = useMemo(
    () =>
      route && routes[route] ? { route: routes[route], key: route } : undefined,
    [route, routes]
  );

  if (!mountain) {
    return null;
  }

  return (
    <Page>
      <Switch>
        <RouterRoute
          path={['/routes/:mountain/events/:route', '/routes/:mountain']}
          exact
        >
          <H1>
            {mountain}
            <div
              style={{
                textAlign: 'center',
                display: 'block',
                fontSize: '12px',
              }}
            >
              routes {routeWithKeys.length} | go to{' '}
              <Link to={`/routes/${mountain}/reorder`}>reorder list</Link>
            </div>
          </H1>
          {selectedRoute && (
            <AwesomePaper>
              <Events
                route={selectedRoute.route}
                name={selectedRoute.key}
                mountain={mountain}
              />
            </AwesomePaper>
          )}
          <AwesomePaper>
            <RoutesTable
              data={routeWithKeys}
              updateRoute={updateRoute}
              removeRoute={removeRoute}
              mountain={mountain}
            />
          </AwesomePaper>
        </RouterRoute>
        <RouterRoute path={'/routes/:mountain/reorder'} exact>
          <H1>
            Order {mountain} routes
            <div
              style={{
                textAlign: 'center',
                display: 'block',
                fontSize: '12px',
              }}
            >
              Go to <Link to={`/routes/${mountain}`}>route list</Link>
            </div>
          </H1>
          <AwesomePaper>
            <ReorderList
              items={routeWithKeys}
              onReorderEnd={async (items) => {
                if (mountain) {
                  return updateRoutesOrder(mountain, items);
                }
              }}
            />
          </AwesomePaper>
        </RouterRoute>
      </Switch>
      <AddRouteDialog
        open={routeDialogOpen}
        handleClose={() => setRouteDialogOpen(false)}
        onCreate={(routeName) => {
          if (!routeName) {
            return;
          }

          return addRoute(routeName);
        }}
      />
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => setRouteDialogOpen(true)}
      >
        <AddIcon />
      </Fab>
    </Page>
  );
};

export default Routes;
