import { makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
}));

const AwesomePaper: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} variant="outlined">
      {children}
    </Paper>
  );
};

export default AwesomePaper;
