import React, { FC } from 'react';
import Page from '../../components/page';
import { useItems } from '../../hooks/useItems';
import MaterialTable from 'material-table';
import {
  basicColumns,
  rareColumns,
  epicColumns,
  legendaryColumns,
} from './columnTypes';
import { Rarity } from '../../constants/rarity';

const columnsForRarity = (rarity: string) => {
  switch (rarity) {
    case 'BASIC':
      return basicColumns;
    case 'RARE':
      return rareColumns;
    case 'EPIC':
      return epicColumns;
    default:
      return legendaryColumns;
  }
};

const Items: FC = () => {
  const { items, addItem, removeItem, updateItem } = useItems();

  return (
    <Page>
      {Rarity.map((rarity) => (
        <MaterialTable
          key={rarity}
          style={{ marginTop: '25px' }}
          title={`${rarity} Gear`}
          options={{ paging: false }}
          columns={columnsForRarity(rarity)}
          data={items.filter((item) => item.rarity === rarity)}
          editable={{
            onRowAdd: async (newData) => {
              try {
                await addItem(newData.key, { ...newData, rarity });
              } catch (e) {
                alert(e);
              }
            },
            onRowDelete: (oldData) => removeItem(oldData.key),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (!oldData) {
                  alert('Old item does not exist.');
                  return reject();
                }

                updateItem(oldData.key, newData.key, newData)
                  .then(() => resolve())
                  .catch((e) => {
                    alert(`Gear update failed: ${e.message}`);
                    reject();
                  });
              }),
          }}
        />
      ))}
    </Page>
  );
};

export default Items;
