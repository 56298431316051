import { useState, useEffect, useCallback } from 'react';
import { useDatabase } from './useDatabase';
import {
  Mountain,
  MountainsResponse,
  MountainFirebase,
} from '../types/mountains';
import { convertObjectToArray } from '../utils/convertObjectToArray';
import { MOUNTAINS } from '../firebase/refs';

const initMountainData = (
  data?: Partial<MountainFirebase>
): MountainFirebase => {
  data = data ?? {};

  return {
    gold: data.gold ? Number(data.gold) : 0,
    level: data.level ? Number(data.level) : 0,
    routes: data.routes ?? {},
  };
};

export const useMountains = () => {
  const database = useDatabase();
  const [mountains, setMountains] = useState<Mountain[]>([]);

  const addMountain = useCallback(
    (name: string, data?: Partial<MountainFirebase>) =>
      new Promise((resolve, reject) => {
        if (!name) {
          return reject(new Error('Name is required'));
        }

        return resolve(
          database.ref(MOUNTAINS).child(name).set(initMountainData(data))
        );
      }),
    [database]
  );

  const removeMountain = useCallback(
    (mountain: string) => database.ref(MOUNTAINS).child(mountain).remove(),
    [database]
  );

  const updateMountain = useCallback(
    async (
      oldMountainName: string,
      newMountainName: string,
      data: Partial<MountainFirebase>
    ) => {
      if (!oldMountainName || !newMountainName) {
        throw new Error('Name of mountain is required.');
      }

      const mountainsRef = database.ref(MOUNTAINS);

      const currentMountainSnap = await mountainsRef
        .child(oldMountainName)
        .once('value');

      const mountainData = initMountainData(data);
      mountainData.routes = currentMountainSnap.val().routes ?? {};

      const dataToUpdate = {
        [newMountainName]: mountainData,
        ...(oldMountainName !== newMountainName && { [oldMountainName]: null }),
      };

      return mountainsRef.update(dataToUpdate);
    },
    [database]
  );

  useEffect(() => {
    const listener = database.ref(MOUNTAINS).on('value', (snapshot) => {
      if (snapshot) {
        const mountainsResponse = snapshot.val() as MountainsResponse;
        setMountains(convertObjectToArray(mountainsResponse));
      }
    });

    return () => {
      listener(null);
    };
  }, [database]);

  return { mountains, addMountain, removeMountain, updateMountain };
};
