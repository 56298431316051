import React, { FC } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { AdapterLink } from './adapter-link';

export const LinkItem: FC<{ to: string; icon: JSX.Element; title: string }> = ({
  to,
  icon,
  title,
}) => (
  <ListItem button component={AdapterLink} to={to}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);
