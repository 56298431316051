import MaterialTable, { Column } from 'material-table';
import React, { FC, useEffect } from 'react';
import Page from '../../components/page';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useFilePicker } from 'react-sage';
import { useSpeedConverter } from '../../hooks/useSpeedConverter';
import { useProcessSpeedConverterCSV } from '../../hooks/useProcessSpeedConverterCSV';

const columns: Column<{ key: string; value: number }>[] = [
  {
    title: 'Speed Points',
    field: 'key',
    type: 'numeric',
    sorting: true,
    defaultSort: 'asc',
  },
  { title: 'Real Speed', field: 'value', type: 'numeric' },
];

const Speed: FC = () => {
  const {
    speedConverter,
    addSpeedConverter,
    addAllSpeedConverter,
    updateSpeedConverter,
    removeSpeedConverter,
    loadingImport,
  } = useSpeedConverter();

  const { files, errors, onClick, HiddenFileInput } = useFilePicker();

  const { data, processDataError } = useProcessSpeedConverterCSV(files);

  useEffect(() => {
    if (data.length && processDataError.length === 0) {
      addAllSpeedConverter(data);
    }
  }, [data, processDataError.length, addAllSpeedConverter]);

  return (
    <Page>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {processDataError.length > 0 && (
          <Typography variant="h3" component="h3">
            {processDataError}
          </Typography>
        )}
        {errors.hasInvalidFileSize && (
          <Typography variant="h3" component="h3">
            Invalid file size
          </Typography>
        )}
        <HiddenFileInput accept="text/csv" multiple={false}></HiddenFileInput>
        <Button
          onClick={onClick}
          color="primary"
          style={{ marginBottom: '1rem', marginLeft: 'auto' }}
        >
          Import CSV
        </Button>
        {loadingImport && (
          <Typography variant="h3" component="h3">
            {'Loading...'}
          </Typography>
        )}
        <MaterialTable
          title="Speed Converter"
          columns={columns}
          data={speedConverter}
          options={{ paging: false, exportButton: true }}
          editable={{
            onRowAdd: async (newData) => {
              try {
                await addSpeedConverter(newData.key, newData.value);
              } catch (e) {
                alert(e.message);
              }
            },
            onRowDelete: async (oldData) =>
              await removeSpeedConverter(oldData.key),
            onRowUpdate: async (newData, oldData) => {
              if (!oldData) {
                alert('Old data does not exist.');
                return;
              }

              try {
                await updateSpeedConverter(
                  oldData.key,
                  newData.key,
                  newData.value
                );
              } catch (e) {
                alert(e.message);
              }
            },
          }}
        />
      </div>
    </Page>
  );
};

export default Speed;
