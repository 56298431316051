import React, { FC } from 'react';
import { Route } from '../../../types/routes';
import { RouteEvent } from '../../../types/route-event';
import { isChangeStatEvent } from '../../../utils/predicates-route-event';
import { StatEvent } from '../../../types/stat-events';
import { Item } from '../../../types/items';
import Warning from '../../warning';

export const AdditionalInfo: FC<{
  route: Route;
  data: RouteEvent;
  statEvents: StatEvent[];
  items: Item[];
}> = ({ data, statEvents, items }) => {
  // if (isSpawnItemEvent(data)) {
  //   const item = items.find((item) => data.item === item.key);
  //   if (item) {
  //     return (
  //       <span>
  //         {data.probability}% to spawn {item.key}{' '}
  //         <em>
  //           ({item.stat_affects} - {item.value})
  //         </em>
  //       </span>
  //     );
  //   } else {
  //     return <Warning tooltip="Item does not exists!">{data.item}</Warning>;
  //   }
  // }

  if (isChangeStatEvent(data)) {
    const statEvent = statEvents.find((event) => data.event === event.key);
    if (statEvent) {
      return (
        <span>
          {data.probability}% to {statEvent.key}{' '}
          <em>
            ({statEvent.stat_affects} - {statEvent.value})
          </em>
        </span>
      );
    } else {
      return (
        <Warning tooltip="Stat Event does not exists!">{data.event}</Warning>
      );
    }
  }

  return null;
};
