import React, { FC } from 'react';
import Page from '../../components/page';
import { useStatEvents } from '../../hooks/useStatEvents';
import MaterialTable, { Column } from 'material-table';
import { StatEvent } from '../../types/stat-events';
import { convertPrimitiveObjectToArray } from '../../utils/convertObjectToArray';
import { primitiveArrayToObject } from '../../utils/convertArrayToObject';
import { EventStats } from '../../constants/eventStat';
import { EventCategories } from '../../constants/eventCategory';

const columns: Column<StatEvent>[] = [
  { title: 'Name', field: 'key' },
  {
    title: 'Category',
    field: 'category',
    lookup: primitiveArrayToObject(EventCategories),
  },
  {
    title: 'Affect',
    field: 'stat_affects',
    lookup: primitiveArrayToObject(EventStats),
  },
  { title: 'Value', field: 'value', type: 'numeric' },
];

const messageColumns: Column<{ key: string; value: string }>[] = [
  { title: 'Message', field: 'value' },
];

const StatEvents: FC = () => {
  const {
    statEvents,
    addMessage,
    removeMessage,
    addStatEvent,
    removeStatEvent,
    updateStatEvent,
  } = useStatEvents();

  return (
    <Page>
      <MaterialTable
        title="List of stat events"
        options={{ paging: false }}
        columns={columns}
        data={statEvents}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              addStatEvent(
                newData.key,
                newData.category,
                newData.stat_affects,
                newData.value
              )
                .then(() => resolve())
                .catch((e) => {
                  alert(`Add item failed: ${e.message}`);
                  reject();
                });
            }),
          onRowDelete: (oldData) => removeStatEvent(oldData.key),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              if (!oldData) {
                alert('Old stat event does not exist.');
                return reject();
              }

              updateStatEvent(oldData.key, newData.key, newData)
                .then(() => resolve())
                .catch((e) => {
                  alert(`Mountain update failed: ${e.message}`);
                  reject();
                });
            }),
        }}
        detailPanel={(rowData) => {
          const messages = convertPrimitiveObjectToArray(rowData.message);

          return (
            <MaterialTable
              title={`List of ${rowData.key} messages`}
              columns={messageColumns}
              options={{
                paging: false,
                search: false,
                header: false,
                rowStyle: {
                  backgroundColor: '#E7E7E7',
                },
              }}
              data={messages}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    addMessage(rowData.key, newData.value)
                      .then(() => resolve())
                      .catch((e) => {
                        alert(`Add message failed: ${e.message}`);
                        reject();
                      });
                  }),
                onRowDelete: ({ key }) =>
                  new Promise((resolve, reject) => {
                    removeMessage(rowData.key, key)
                      .then(() => resolve())
                      .catch((e) => {
                        alert(`Remove message failed: ${e.message}`);
                        reject();
                      });
                  }),
              }}
            />
          );
        }}
      />
    </Page>
  );
};

export default StatEvents;
