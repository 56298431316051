import { Column } from 'material-table';
import { Item } from '../../types/items';
import { primitiveArrayToObject } from '../../utils/convertArrayToObject';
import { GearPart } from '../../constants/gearPart';
import { GearStats } from '../../constants/gearStat';
import { EventCategories } from '../../constants/eventCategory';

export const basicColumns: Column<Item>[] = [
  { title: 'Name', field: 'key' },
  {
    title: 'Gear Part',
    field: 'gear_part',
    lookup: primitiveArrayToObject(GearPart),
  },
  {
    title: 'Affect',
    field: 'affects',
    lookup: primitiveArrayToObject([...GearStats, ...EventCategories]),
  },
  { title: 'Value', field: 'value', type: 'numeric' },
  { title: 'Item Level', field: 'item_level', type: 'numeric' },
];

export const rareColumns: Column<Item>[] = [
  { title: 'Name', field: 'key' },
  {
    title: 'Gear Part',
    field: 'gear_part',
    lookup: primitiveArrayToObject(GearPart),
  },
  {
    title: 'Stat Affects 1',
    field: 'stat_affects_1',
    lookup: primitiveArrayToObject(GearStats),
  },
  { title: 'Stat Value 1', field: 'stat_value_1', type: 'numeric' },
  {
    title: 'Stat Affects 2',
    field: 'stat_affects_2',
    lookup: primitiveArrayToObject(GearStats),
  },
  { title: 'Stat Value 2', field: 'stat_value_2', type: 'numeric' },
  { title: 'Item Level', field: 'item_level', type: 'numeric' },
];

export const epicColumns: Column<Item>[] = [
  { title: 'Name', field: 'key' },
  {
    title: 'Gear Part',
    field: 'gear_part',
    lookup: primitiveArrayToObject(GearPart),
  },
  {
    title: 'Stat Affects',
    field: 'stat_affects',
    lookup: primitiveArrayToObject(GearStats),
  },
  { title: 'Stat Value', field: 'stat_value', type: 'numeric' },
  {
    title: 'Category Affects',
    field: 'category_affects',
    lookup: primitiveArrayToObject(EventCategories),
  },
  { title: 'Category Value', field: 'category_value', type: 'numeric' },
  { title: 'Item Level', field: 'item_level', type: 'numeric' },
];

export const legendaryColumns: Column<Item>[] = [
  { title: 'Name', field: 'key' },
  {
    title: 'Gear Part',
    field: 'gear_part',
    lookup: primitiveArrayToObject(GearPart),
  },
  {
    title: 'Affects 1',
    field: 'affects_1',
    lookup: primitiveArrayToObject([...GearStats, ...EventCategories]),
  },
  { title: 'Value 1', field: 'value_1', type: 'numeric' },
  {
    title: 'Affects 2',
    field: 'affects_2',
    lookup: primitiveArrayToObject([...GearStats, ...EventCategories]),
  },
  { title: 'Value 2', field: 'value_2', type: 'numeric' },
  {
    title: 'Category Affects',
    field: 'category_affects',
    lookup: primitiveArrayToObject(EventCategories),
  },
  { title: 'Category Value', field: 'category_value', type: 'numeric' },
  { title: 'Item Level', field: 'item_level', type: 'numeric' },
];
