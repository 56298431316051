import { useState, useEffect, useCallback } from 'react';
import { SPEED, CONVERT } from '../firebase/refs';
import { convertPrimitiveObjectToArray } from '../utils/convertObjectToArray';
import { useDatabase } from './useDatabase';
import {
  SpeedConverter,
  SpeedConverterResponse,
} from '../types/speedConverter';
import { isInt } from '../types/items';

export const useSpeedConverter = () => {
  const database = useDatabase();
  const [speedConverter, setSpeedConverter] = useState<SpeedConverter[]>([]);
  const [loadingImport, setLoadingImport] = useState(false);

  const addSpeedConverter = useCallback(
    async (logicSpeed: string, realSpeed: number) => {
      if (!logicSpeed) {
        throw new Error('Logic speed is required.');
      }

      if (typeof Number(logicSpeed) !== 'number') {
        throw new Error('Logic speed needs to be integer.');
      }

      if (!isInt(realSpeed)) {
        throw new Error('Real speed needs to be integer.');
      }

      const fireSpeedConverter = database.ref(SPEED).child(logicSpeed);

      return fireSpeedConverter.set(Number(realSpeed));
    },
    [database]
  );

  const addAllSpeedConverter = useCallback(
    async (speedConverter: SpeedConverter[]) => {
      setLoadingImport(true);

      await database.ref(SPEED).remove();

      speedConverter.forEach((speed) =>
        addSpeedConverter(speed.key, speed.value)
      );

      setLoadingImport(false);
    },
    [database, addSpeedConverter]
  );

  const updateSpeedConverter = useCallback(
    async (oldLogicSpeed: string, newLogicSpeed: string, realSpeed: number) => {
      if (!oldLogicSpeed || !newLogicSpeed) {
        throw new Error('Logic speed is required!');
      }

      if (typeof Number(newLogicSpeed) !== 'number') {
        throw new Error('Logic speed needs to be integer.');
      }

      if (!isInt(realSpeed)) {
        throw new Error('Real speed needs to be integer.');
      }

      const speedRef = database.ref(SPEED);

      const dataToUpdate = {
        [newLogicSpeed]: Number(realSpeed),
        ...(oldLogicSpeed !== newLogicSpeed && { [oldLogicSpeed]: null }),
      };

      return speedRef.update(dataToUpdate);
    },
    [database]
  );

  const removeSpeedConverter = useCallback(
    async (logicSpeed: string) => {
      return database.ref(CONVERT(logicSpeed)).remove();
    },
    [database]
  );

  useEffect(() => {
    const listener = database.ref(SPEED).on('value', (snapshot) => {
      if (snapshot) {
        const speedConverterResponse = snapshot.val() as SpeedConverterResponse;
        if (speedConverterResponse) {
          setSpeedConverter(
            convertPrimitiveObjectToArray(speedConverterResponse)
          );
        }
      }
    });

    return () => {
      listener(null);
    };
  }, [database]);

  return {
    speedConverter,
    addSpeedConverter,
    addAllSpeedConverter,
    updateSpeedConverter,
    removeSpeedConverter,
    loadingImport,
  };
};
