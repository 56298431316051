import { useEffect, useState, useCallback } from 'react';
import { STAT_EVENTS, STAT_EVENT } from '../firebase/refs';
import { useDatabase } from './useDatabase';
import {
  convertObjectToArray,
  convertPrimitiveObjectToArray,
} from '../utils/convertObjectToArray';
import { EventStats } from '../constants/eventStat';
import {
  StatEvent,
  StatEventFirebase,
  StatEventsResponse,
} from '../types/stat-events';
import { EventCategories } from '../constants/eventCategory';

const initStatEventData = (
  data?: Partial<StatEventFirebase>
): StatEventFirebase => {
  data = data ?? {};

  return {
    stat_affects: data.stat_affects ?? EventStats[0],
    category: data.category ?? EventCategories[0],
    value: data.value ? Number(data.value) : 0,
    message: data.message ?? {
      0: 'statevent.message',
    },
  };
};

export const useStatEvents = () => {
  const database = useDatabase();
  const [statEvents, setStatEvents] = useState<StatEvent[]>([]);

  const addMessage = useCallback(
    async (statEvent: string, message: string) => {
      if (!statEvent) {
        throw new Error('Set stat event');
      }

      if (!message) {
        throw new Error('Set message');
      }

      const statEventFirebase = database.ref(STAT_EVENT(statEvent));
      return statEventFirebase.child('message').push(message);
    },
    [database]
  );

  const updateStatEvent = useCallback(
    async (
      oldStatEventName: string,
      newStatEventName: string,
      data: Partial<StatEventFirebase>
    ) => {
      if (!oldStatEventName || !newStatEventName) {
        throw new Error('Name of stat event is required.');
      }

      const statEventRef = database.ref(STAT_EVENTS);
      const currentStatEventSnap = await statEventRef
        .child(oldStatEventName)
        .once('value');

      const statEventData = initStatEventData(data);
      statEventData.message = currentStatEventSnap.val().message ?? {
        0: 'statevent.message',
      };

      const dataToUpdate = {
        [newStatEventName]: statEventData,
        ...(oldStatEventName !== newStatEventName && {
          [oldStatEventName]: null,
        }),
      };

      return statEventRef.update(dataToUpdate);
    },
    [database]
  );

  const addStatEvent = useCallback(
    async (name: string, category: string, affect: string, value: number) => {
      if (!name) {
        throw new Error('Name is required');
      }

      if (!category) {
        throw new Error('Category is required');
      }

      if (!affect) {
        throw new Error('Affect is required');
      }

      value = value ? Number(value) : 0;

      if (typeof value !== 'number') {
        throw new Error('Number is required');
      }

      const statEvent = database.ref(STAT_EVENTS).child(name);
      await statEvent.set({ category, stat_affects: affect, value });
      return statEvent.child('message').push('statevent.message');
    },
    [database]
  );

  const removeStatEvent = useCallback(
    async (statEvent: string) => {
      return database.ref(STAT_EVENT(statEvent)).remove();
    },
    [database]
  );

  const removeMessage = useCallback(
    async (statEvent: string, key: string) => {
      if (!statEvent) {
        throw new Error('Set stat event');
      }

      if (!key) {
        throw new Error('Set key of stat event');
      }

      const messagesRef = database.ref(STAT_EVENT(statEvent)).child('message');

      const messages = await messagesRef.once('value');
      const messagesVal = messages.val() as Record<string, StatEvent>;

      if (convertPrimitiveObjectToArray(messagesVal).length < 2) {
        throw new Error('At least one message required.');
      }

      return messagesRef.child(key).remove();
    },
    [database]
  );

  useEffect(() => {
    const listener = database.ref(STAT_EVENTS).on('value', (snapshot) => {
      if (snapshot) {
        const statEventsResponse = snapshot.val() as StatEventsResponse;
        if (statEventsResponse) {
          setStatEvents(convertObjectToArray(statEventsResponse));
        }
      }
    });

    return () => {
      listener(null);
    };
  }, [database]);

  return {
    statEvents,
    addMessage,
    removeMessage,
    addStatEvent,
    removeStatEvent,
    updateStatEvent,
  };
};
