import React, { FC } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { LogForm } from '../route-events-form/LogForm';
import { StatEventForm } from '../route-events-form/StatEventForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}));

export const AddRouteEvent: FC<{
  routeName: string;
  mountain: string;
}> = ({ routeName, mountain }) => {
  const classes = useStyles();

  return (
    <ButtonGroup
      className={classes.root}
      variant="text"
      color="primary"
      aria-label="text primary button group"
    >
      <LogForm routeName={routeName} mountain={mountain} />
      <StatEventForm routeName={routeName} mountain={mountain} />
    </ButtonGroup>
  );
};
