export function convertObjectToArray<T extends {}>(
  data: Record<string, T> = {}
) {
  return Object.entries(data).map(([key, value]) => ({ key, ...value }));
}

export function convertPrimitiveObjectToArray<T extends {}>(
  data: Record<string, T>
) {
  return Object.entries(data).map(([key, value]) => ({ key, value }));
}
