import { GearPartItem } from './gearPart';
import { RarityItem } from './rarity';
import { GearStatItem } from './gearStat';
import { EventCategoryItem } from './eventCategory';

export interface ItemCore {
  rarity: RarityItem;
  gear_part: GearPartItem;
  item_level: number;
}

export interface BasicItem extends ItemCore {
  affects: GearStatItem | EventCategoryItem;
  value: number;
}

export const isBasicItem = (item: Item | ItemFirebase): item is BasicItem =>
  item.rarity === 'BASIC';

export interface RareItem extends ItemCore {
  stat_affects_1: GearStatItem;
  stat_value_1: number;
  stat_affects_2: GearStatItem;
  stat_value_2: number;
}

export const isRareItem = (item: Item | ItemFirebase): item is RareItem =>
  item.rarity === 'RARE';

export interface EpicItem extends ItemCore {
  stat_affects: GearStatItem;
  stat_value: number;
  category_affects: EventCategoryItem;
  category_value: number;
}

export const isEpicItem = (item: Item | ItemFirebase): item is EpicItem =>
  item.rarity === 'EPIC';

export interface LegendaryItem extends ItemCore {
  affects_1: GearStatItem | EventCategoryItem;
  value_1: number;
  affects_2: GearStatItem | EventCategoryItem;
  value_2: number;
  category_affects: EventCategoryItem;
  category_value: number;
}

export const isLegendaryItem = (
  item: Item | ItemFirebase
): item is LegendaryItem => item.rarity === 'LEGENDARY';

export type ItemFirebase = BasicItem | RareItem | EpicItem | LegendaryItem;

export type Item = ItemFirebase & {
  key: string; // Item name is a key in Firebase DB
};

export type ItemsResponse = Record<string, ItemFirebase>;

// Material Table is sending values as string
export const isInt = (x: any) => x !== '' && Number(x) % 1 === 0;

export const parseFirebaseItem = (data: ItemFirebase): ItemFirebase => {
  if (!data.rarity) {
    throw new Error('RARITY is required!');
  }

  if (!data.gear_part) {
    throw new Error('GEAR PART is required!');
  }

  if (!isInt(data.item_level)) {
    throw new Error('ITEM LEVEL needs to be number!');
  }

  const coreItem = {
    rarity: data.rarity,
    gear_part: data.gear_part,
    item_level: +data.item_level,
  };

  if (isBasicItem(data)) {
    if (!data.affects) {
      throw new Error('AFFECTS is required!');
    }

    if (!isInt(data.value)) {
      throw new Error('VALUE needs to be number!');
    }

    return {
      ...coreItem,
      affects: data.affects,
      value: +data.value,
    };
  }

  if (isRareItem(data)) {
    if (!data.stat_affects_1) {
      throw new Error('STAT AFFECTS 1 is required!');
    }

    if (!data.stat_affects_2) {
      throw new Error('STAT AFFECTS 2 is required!');
    }

    if (!isInt(data.stat_value_1)) {
      throw new Error('STAT VALUE 1 needs to be number!');
    }

    if (!isInt(data.stat_value_2)) {
      throw new Error('STAT VALUE 2 needs to be number!');
    }

    return {
      ...coreItem,
      stat_affects_1: data.stat_affects_1,
      stat_value_1: +data.stat_value_1,
      stat_affects_2: data.stat_affects_2,
      stat_value_2: +data.stat_value_2,
    };
  }

  if (isEpicItem(data)) {
    if (!data.stat_affects) {
      throw new Error('STAT AFFECTS is required!');
    }

    if (!data.category_affects) {
      throw new Error('CATEGORY AFFECTS is required!');
    }

    if (!isInt(data.stat_value)) {
      throw new Error('STAT VALUE needs to be number!');
    }

    if (!isInt(data.category_value)) {
      throw new Error('CATEGORY VALUE needs to be number!');
    }

    return {
      ...coreItem,
      stat_affects: data.stat_affects,
      stat_value: +data.stat_value,
      category_affects: data.category_affects,
      category_value: +data.category_value,
    };
  }

  if (isLegendaryItem(data)) {
    if (!data.affects_1) {
      throw new Error('AFFECTS 1 is required!');
    }

    if (!data.affects_2) {
      throw new Error('AFFECTS 2 is required!');
    }

    if (!data.category_affects) {
      throw new Error('CATEGORY AFFECTS is required!');
    }

    if (!isInt(data.value_1)) {
      throw new Error('VALUE 1 needs to be number!');
    }

    if (!isInt(data.value_2)) {
      throw new Error('VALUE 2 needs to be number!');
    }

    if (!isInt(data.category_value)) {
      throw new Error('CATEGORY VALUE needs to be number!');
    }

    return {
      ...coreItem,
      affects_1: data.affects_1,
      value_1: +data.value_1,
      affects_2: data.affects_2,
      value_2: +data.value_2,
      category_affects: data.category_affects,
      category_value: +data.category_value,
    };
  }

  throw new Error('INVALID TYPE!!!');
};
