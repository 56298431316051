import { useCallback, useMemo } from 'react';
import { useDatabase } from './useDatabase';
import { RouteEvent } from '../types/route-event';
import { ROUTE_EVENTS } from '../firebase/refs';

export const useRouteEvents = (mountain: string, route: string) => {
  const database = useDatabase();

  const databaseRouteEventRef = useMemo(
    () => database.ref(ROUTE_EVENTS(mountain, route)),
    [database, mountain, route]
  );

  const addEvent = useCallback(
    (event: RouteEvent) => {
      return databaseRouteEventRef.push(event);
    },
    [databaseRouteEventRef]
  );

  const removeEvent = useCallback(
    (id: string) => {
      return databaseRouteEventRef.child(id).remove();
    },
    [databaseRouteEventRef]
  );

  return { addEvent, removeEvent };
};
