import React from 'react';
import { useGuest } from '../hooks/useUser';
import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    height: '100%',
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const { signIn } = useGuest();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={signIn}
        endIcon={<Icon>send</Icon>}
      >
        Sign In With Google
      </Button>
    </Grid>
  );
};

export default SignIn;
