import { ProbabilityEvent } from '../../../types/route-event';

export interface ProbabilityPartialForm
  extends Omit<ProbabilityEvent, 'end_point' | 'end_distance'> {
  end_point?: number;
  end_distance?: number;
}

export function handleProbabilityForm(values: ProbabilityPartialForm) {
  values.end_point = values.end_point || values.start_point;
  values.end_distance = values.end_distance || values.start_distance;

  return values;
}
