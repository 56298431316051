import React, { useEffect, useMemo, useState } from 'react';
import { createContext, FC } from 'react';
import firebase from 'firebase/app';
import { auth } from './firebase';
import { FullLoading } from '../components/Loading';

export type User = firebase.User;

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export type AuthUser = {
  user: User;
  isAuth: true;
  signOut: () => void;
};

export type Guest = {
  user: null;
  isAuth: false;
  signIn: () => void;
};

type UserContextData = AuthUser | Guest;

export const isAuth = (user: UserContextData): user is AuthUser => user.isAuth;
export const isGuest = (user: UserContextData): user is Guest => !user.isAuth;

export const UserContext = createContext<UserContextData>({
  user: null,
  signIn: () => undefined,
  isAuth: false,
});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={useMemo(() => {
        if (user) {
          return {
            user,
            isAuth: true,
            signOut: () => auth.signOut(),
          };
        }
        return {
          user: null,
          isAuth: false,
          signIn: () => auth.signInWithPopup(googleAuthProvider),
        };
      }, [user])}
    >
      {loading ? <FullLoading /> : children}
    </UserContext.Provider>
  );
};
