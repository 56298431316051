import React, { createContext, FC } from 'react';
import firebase from 'firebase';
import { database } from './firebase';

export const DatabaseContext = createContext<firebase.database.Database>(
  database
);

export const DatabaseProvider: FC = ({ children }) => {
  return (
    <DatabaseContext.Provider value={database}>
      {children}
    </DatabaseContext.Provider>
  );
};
