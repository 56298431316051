import React from 'react';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { GenericRouteEventForm } from './GenericRouteEventForm';
import { useRouteEvents } from '../../../hooks/useRouteEvents';
import { handleProbabilityForm, ProbabilityPartialForm } from './_actions';
import { FormikProps } from 'formik';

const useStyles = makeStyles((theme) => ({
  routeTitle: {
    color: 'primary',
    textDecoration: 'italic',
  },
}));

const ProbabilityFormSchema = Yup.object().shape({
  start_distance: Yup.number().min(1, 'At least 1').required('Required'),
  end_point: Yup.number().min(
    Yup.ref('start_point'),
    'At least as start point'
  ),
  end_distance: Yup.number().min(
    Yup.ref('start_distance'),
    'At least as start distance'
  ),
  probability: Yup.number().min(0.01, 'Minimal 0.01').max(100, 'Maximum 100'),
});

export function ProbabilityEventForm<
  T extends ProbabilityPartialForm = ProbabilityPartialForm
>({
  routeName,
  mountain,
  render,
  initialValues,
  validationSchema,
  title,
}: {
  title: string;
  routeName: string;
  mountain: string;
  initialValues: T;
  render: (arg: FormikProps<T>) => React.ReactNode;
  validationSchema?: any | (() => any);
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { addEvent } = useRouteEvents(mountain, routeName);

  const schema = validationSchema
    ? ProbabilityFormSchema.concat(validationSchema)
    : ProbabilityFormSchema;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {title}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          {title} for&nbsp;
          <span className={classes.routeTitle}>{routeName}</span>
        </DialogTitle>
        <DialogContent dividers>
          <GenericRouteEventForm<T>
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              addEvent(handleProbabilityForm(values)).then(() => {
                setSubmitting(false);
                handleClose();
              });
            }}
            validationSchema={schema}
            children={(props) => {
              const {
                values,
                errors,
                handleChange,
                isSubmitting,
                handleBlur,
              } = props;
              return (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="End Point"
                    type="text"
                    name="end_point"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.end_point}
                    error={!!errors.end_point}
                    helperText={errors.end_point}
                    placeholder="optional => start point == end point"
                    fullWidth
                    disabled={isSubmitting}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Start Distance"
                    type="text"
                    name="start_distance"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.start_distance}
                    error={!!errors.start_distance}
                    helperText={errors.start_distance}
                    fullWidth
                    disabled={isSubmitting}
                    required
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="End Distance"
                    type="text"
                    name="end_distance"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.end_distance}
                    error={!!errors.end_distance}
                    helperText={errors.end_distance}
                    placeholder="optional => start distance == end distance"
                    fullWidth
                    disabled={isSubmitting}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Probability"
                    type="text"
                    name="probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.probability}
                    error={!!errors.probability}
                    helperText={errors.probability}
                    placeholder="optional => start distance == end distance"
                    fullWidth
                    disabled={isSubmitting}
                  />
                  {render(props)}
                </>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
