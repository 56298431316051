export const PLAYER_SETTINGS = 'player-settings';
export const LEVEL = (level: string) => `${PLAYER_SETTINGS}/${level}`;
export const MOUNTAINS = 'mountains';
export const ROUTES = (mountain: string) => `${MOUNTAINS}/${mountain}/routes`;
export const GEAR = 'gear';
export const ITEM = (item: string) => `${GEAR}/${item}`;
export const STAT_EVENTS = 'stat-events';
export const STAT_EVENT = (statEvent: string) => `${STAT_EVENTS}/${statEvent}`;
export const SPEED = 'speed';
export const CONVERT = (speed: string) => `${SPEED}/${speed}`;
export const ROUTE = (mountain: string, route: string) =>
  `${ROUTES(mountain)}/${route}`;
export const ROUTE_EVENTS = (mountain: string, route: string) =>
  `${ROUTE(mountain, route)}/events`;
export const ROUTE_EVENT = (mountain: string, route: string, event: string) =>
  `${ROUTE_EVENTS(mountain, route)}/${event}`;
export const FIREBASE_CONSOLE_URL = (path: string) =>
  `https://console.firebase.google.com/u/0/project/the-sherpa-89ec9/database/the-sherpa-89ec9/data${encodeURIComponent(
    '/' + path
  )}`.replace(/%2F/g, '~2F');
